<template>
  <Toast position="bottom-right" />
  <div :style='`background-image:url(${BackgroundImage});`' class="home tw-bg-gray-200 tw-flex tw-h-screen">
      <Sidebar v-model:visible="slideVisible" position='left' class="tw-w-full! home-sidebar md:tw-w-5/12! xl:tw-w-1/2! tw-text-center!">
            <MenuMobile :isMenuBar='true' :isSlide='true' @clicked='handleMenuClick(false)' />
      </Sidebar>


      <MenuMobile :isMenuBar='true' />

      <div class='tw-w-full tw-flex tw-flex-col tw-justify-start tw-items-center'>
       
        <!-- Contenedor Barra superior -->
        <div class='tw-w-full tw-pt-2  tw-px-2 tw-justify-self-start tw-mb-2'>
          
          <!--Barra superior -->
          <div class='tw-w-full tw-h-16 p-shadow-5 tw-bg-gray-100 tw-rounded'> 
              
              <div class='tw-w-full tw-flex tw-items-center tw-h-full tw-p-2 md:tw-px-8'>
                <Button class='lg:tw-hidden p-button-rounded p-button-outlined p-button-sm' icon='pi pi-bars' @click="handleMenuClick(!slideVisible)"></Button>
                
                <div class='tw-ml-auto tw-flex tw-items-center'>
                    <!-- <i  class="pi pi-bell p-button-secondary tw-mr-3 tw-cursor-pointer " v-badge.info ></i> -->
                    <!-- <strong>{{$store.state.pacienteLogueado.nombre}}</strong> -->
                    <Dropdown :modelValue='$store.state.pacienteLogueado' @change='handleChange' :options="[...$store.state.pacientesRelacionados,$store.state.pacienteLogueado]" optionLabel='nombre' class='tw-mr-2 tw-max-w-20ch! sm:tw-max-w-none!' style='color:var(--text-color);' ></Dropdown>
                    <Avatar icon="pi pi-user" class="p-shadow-1 tw-cursor-pointer" size="large" shape="circle" aria-haspopup="true" aria-controls="overlay_menu" @click="toggle" />
                    <Menu id="overlay_menu" ref="menu" :model="dropMenuItems" :popup="true" />
                </div>
              </div>
            
          </div>
        
        </div>

        <!-- Contenedor de la pagina -->
        <router-view />

      </div>
  </div>
  <Dialog class='tw-w-96' :visible="editarPass" :modal="true">
        <template #header> 
            <h3 class=''><b>CAMBIAR CONTRASEÑA</b></h3>
            <hr>
        </template>
        <template #title> <div class='tw-w-full'></div> </template>
          <div  class='p-fluid tw-space-y-4'>
              <div class='p-field'>
                  <Password :loading='isLoad' placeholder='Contraseña Actual' toggleMask v-model="oldpassword" :feedback="false" />
              </div>
              <div class='p-field'>
                  <Password placeholder='Nueva Contraseña' toggleMask v-model="password" >
                      <template #footer>
                          <Divider />
                          <p class="p-mt-2">Sugerencias:</p>
                          <ul class="p-pl-2 p-ml-2 p-mt-0" style="line-height: 1.5">
                              <li>Minimo 8 caracteres</li>
                              <li>Una letra en mayusculas</li>
                              <li>al menos un numero</li>
                          </ul>
                      </template>
                  
                  </Password>
              </div>
              <div class='p-field'>
                  <Password :loading='isLoad' placeholder='Repita la Contraseña' toggleMask v-model="passwordRepeat" :feedback="false" />
              </div>
              
              <span class='tw-w-full tw-text-sm tw-text-red-500'>{{error}}</span>
          </div>
        <template #footer>
            <Button label='Guardar' @click="handleClick"></Button>
            <Button label='Cancelar' class='p-button-link' @click="handleEditarPassClick(false)"></Button>
        </template>
    </Dialog>
  <ConfirmDialog></ConfirmDialog>
</template>

<script lang="ts" >
import { defineComponent, ref } from 'vue';

//import Menubar from 'primevue/menubar';
import Sidebar from 'primevue/sidebar';
import MenuMobile from './MenuMobile.vue';

import Avatar from 'primevue/avatar';

import { useConfirm } from "primevue/useconfirm";
import {  useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { useToast } from 'primevue/usetoast';
import useBackground from '@/hooks/useBackground'
import useLogo from '@/hooks/useLogo';
import axios from '@/plugins/axios'




export default defineComponent({
  name: 'Home',
  components: {
   // Menubar,
    Sidebar,
    Avatar,
    MenuMobile,
  },
  setup: () => {
      const store = useStore()
      const menu = ref();
      const router = useRouter();
      const dialog = useConfirm();
      const BackgroundImage = useBackground();
      const logo = useLogo();
      const toast = useToast();
      const paciente = store.state.pacienteLogueado

      //Password
      const oldpassword = ref('');
      const password = ref('');
      const passwordRepeat = ref('');
      const error = ref();
      const editarPass = ref(false);
      const isLoad = ref(false);
  
      const slideVisible = ref(false);
      const itemActive = ref(0);
      const items = ref([
        {
          id:1,
          icon:'pi pi-plus',
          label:'Nuevo Turno',
          to:'nuevo-turno',
          name:'Nuevo Turno'
        },
        {
          id:2,
          icon:'pi pi-list',
          label:'Mis turnos',
          to:'mis-turnos',
          name:'Mis Turnos'
        },
        {
          id:3,
          icon:'pi pi-folder',
          label:'Resultados de estudios',
          to:'resultados',
          name:'Resultados'
        },
        {
          id:4,
          icon:'pi pi-file-o',
          label:'Recetas',
          //to:'recetas',
          name:'Recetas'
        },
        {
          id:5,
          icon:'pi pi-user-edit',
          label:'Mis datos',
          to:'mis-datos',
          name:'Mis Datos'
        },
        {
          icon:'pi pi-lock',
          label:'Cambiar contraseña',
          command: ()=>{
            password.value = ''
            passwordRepeat.value = ''
            oldpassword.value = ''
            error.value = ''
            editarPass.value = true
          }
        },
        {
          icon:'pi pi-sign-out',
          label:'Cerrar sesion',
          command: ()=>{
            dialog.require({
              message:'Seguro que desea cerrar sesion?',
              header:'Cerrar Sesion',
              icon: 'pi pi-exclamation-triangle',
              rejectLabel:'Si',
              acceptLabel:'No',
              acceptClass:'p-button-link',
              rejectClass:'p-button-primary',
              reject: () => {
                store.dispatch('cerrarSesion')
                router.push('login');
              },
            })
          }
        }
      ])
      const dropMenuItems = ref([
        {
          id:5,
          icon:'pi pi-user-edit',
          label:'Mis datos',
          to:'mis-datos',
          command(){
            itemActive.value = this.id;
            document.querySelectorAll('li.p-menuitem').forEach(li => li.classList.remove('active-link'));
            document.querySelector(`li.p-menuitem:nth-child(${this.id})`)?.classList.add('active-link')
          }
        },
        {
          icon:'pi pi-lock',
          label:'Cambiar contraseña',
          command: ()=>{
            password.value = ''
            passwordRepeat.value = ''
            oldpassword.value = ''
            error.value = ''
            editarPass.value = true
          }
        },
        {
          icon:'pi pi-sign-out',
          label:'Cerrar sesion',
          command: ()=>{
            dialog.require({
              message:'Seguro que desea cerrar sesion?',
              header:'Cerrar Sesion',
              icon: 'pi pi-exclamation-triangle',
              rejectLabel:'Si',
              acceptLabel:'No',
              acceptClass:'p-button-link',
              rejectClass:'p-button-primary',
              reject: () => {
                store.dispatch('cerrarSesion')
                router.push('login');
              },
            })
          }
        }
      ])
      
      const validarContraseñas = () => {
          let valida = true;

          valida = valida && password.value === passwordRepeat.value;

          if(!valida){
              error.value = 'Las contraseñas deben ser iguales';
              passwordRepeat.value = '';
              return valida;
          }

          valida = valida && !(password.value.length < 8);

          if(!valida) {
              error.value = 'La contraseña debe tener mas de 8 caracteres';
              password.value = '';
              passwordRepeat.value = '';
              return valida
          }

          return valida;
      }

      const handleClick = () =>{
          error.value = ""
          if(validarContraseñas()){
              isLoad.value = true;
              axios.post('pacientes/cambio-password', {password:password.value, oldpassword: oldpassword.value, idpaciente: paciente.id})
              .then(()=>{
                editarPass.value = false
                toast.add({severity:'success',summary:'Exito',detail:'Se ha actualizado la contraseña correctamente',life:1500})
              })
              .catch(err =>{
                error.value = "La contraseña actual es incorrecta"
              })
              .finally(()=>isLoad.value =false)
          }

      }

      const handleMenuClick = (bool:boolean) => {
        slideVisible.value = bool;
      }

      const handleEditarPassClick = (bool:boolean) =>{
          password.value = ''
          passwordRepeat.value = ''
          oldpassword.value = ''
          error.value = ''
          editarPass.value = bool;
      }
      
      const toggle = (event:Event) => {
            menu.value.toggle(event);
        };

      const handleChange = (option: any) =>{
        store.dispatch('cambiarPaciente', option.value);
        router.push(`/${store.state.configuracionPortal.usuario}/`);
      }
    

    
    return {BackgroundImage, logo, items, slideVisible, itemActive, menu, dropMenuItems, toggle ,
    password,
    passwordRepeat,
    error,
    oldpassword,
    handleMenuClick,editarPass,handleClick,isLoad ,handleEditarPassClick, handleChange}
  }
});
</script>


<style>
  .home{
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 100vh;
    
  }
  .home .animation{
    animation: entrada .3s ease forwards;
  }
  @keyframes entrada {
    0%{
      opacity:0
    }100%{
      opacity: 1;
    }
  }
  .home ul.p-menubar-root-list, .p-sidebar .p-sidebar-content ul.p-menubar-root-list{
    
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin:20% auto;
    width: 100%;

  }
  .home  ul.p-menubar-root-list .p-menuitem,  .p-sidebar .p-sidebar-content ul.p-menubar-root-list .p-menuitem{
    width: 100%;
  }
  .home  ul.p-menubar-root-list .p-menuitem .link, .p-sidebar .p-sidebar-content ul.p-menubar-root-list .p-menuitem .link{
    margin:.2rem 0px;
    padding: .5em 1em;
    width: 100%;
    border-radius: .5em;
    text-align: left;
    transition: background-color .2s ease, color .2s ease;
  }
  .home  ul.p-menubar-root-list .p-menuitem .link:hover, .p-sidebar .p-sidebar-content ul.p-menubar-root-list .p-menuitem .link:hover{
    background: var(--blue-400) ;
    color: var(--gray-100);
    box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.4), 0px 0px 5px var(--blue-400);
  } 
  .home  ul.p-menubar-root-list .p-menuitem .link.active-link , .p-sidebar .p-sidebar-content ul.p-menubar-root-list .p-menuitem .link.active-link{
    background: var(--blue-400) ;
    color: var(--gray-100);
    box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.4), 0px 0px 5px var(--blue-400);
  } 

  .home .p-menubar-end{
    margin-top: auto;
    margin-bottom: 1rem;
    margin-left: 0px;
    text-align: center;
  }
  .home-sidebar .p-sidebar-content{
    height: 100%;
    
  }
  .home-sidebar .p-sidebar-content{
    padding: 0px !important;
    
  }
  .home .p-menubar .p-menubar-custom, .home .p-menubar .p-menubar-end{
    margin-left:inherit;
  }
</style>
