<template>
    <Toast position="bottom-right"/>
    <div class='tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center md:tw-justify-start nuevo-turno'>
        <Card class='animate tw-w-96 md:tw-ml-3 tw-p-4'>
            <template #title>
                <span>Nueva Cita</span>
            </template>
            <template #content>
                <div class='tw-w-full tw-flex tw-flex-col tw-space-y-4'>
                    
                    <!-- Especialidad -->
                    <Dropdown @change='handleChangeEspecialidad' class='tw-w-full' showClear v-model="especialidad" :options="especialidadesList" optionLabel="descripcion" placeholder="Especialidad" :filter="!isIOS" filterPlaceholder="Buscar.."/>
                    
                    <!-- Medico -->
                    <Dropdown class='tw-w-full' v-model="medico" :options="medicosList" optionLabel="nombre" placeholder="Profesional" :filter="!isIOS" filterPlaceholder="Buscar.."/>
                    
                    <!-- Sucursal -->
                    <Dropdown class='tw-w-full' v-model="sucursal" :options="sucursalesList" optionLabel="direccion" placeholder="Sucursal" :filter="!isIOS" filterPlaceholder="Buscar.."/>
                    

                    <div class="text-left flex flex-col">
                        <Checkbox id='videoConsulta' v-model="filtrarVideoConsulta" :binary="true"/>
                        <label for="videoConsulta" class="ml-2 cursor-pointer">Video consulta</label>
                    </div>  

                    <!-- Toggle avanzadas -->                    
                    <div class='tw-w-full tw-flex tw-justify-end tw-items-center'>
                        <Button class='p-button-link ' label='Avanzada' @click='avanzadosToggle = !avanzadosToggle' :icon="`pi ${avanzadosToggle ? 'pi-minus' : 'pi-plus'}`" iconPos="right" />
                    </div>                                      

                    <!-- Avanzados -->
                    <div class='tw-w-full  tw-flex tw-flex-col tw-space-y-4 tw-transition-all' :class='{"tw-h-0 tw-overflow-hidden":!avanzadosToggle, "tw-h-36 tw-overflow-hidden ":avanzadosToggle}'>
                        <!-- Fecha -->
                        <Calendar class='tw-w-full' v-model="fecha" selectionMode="range"  :yearNavigator="true" yearRange="2000:2030" :monthNavigator='true'></Calendar>
                    
                        <!-- Horas -->
                        
                        
                        <!-- Dias -->
                        <div class='tw-flex! tw-justify-between! tw-items-center! tw-pt-2! tw-text-center'>
                            <div class='p-field-checkbox'>
                                <Checkbox class='tw-hidden!' id='lunes' value='Lunes' v-model="DiasSeleccionados" />
                                <label :class='{active:DiasSeleccionados.some(dia => dia === "Lunes")}' for="lunes" class='boton-dia tw-py-1! tw-px-2! tw-cursor-pointer tw-select-none'>Lu</label>
                            </div>
                            <div class='p-field-checkbox '>
                                <Checkbox class='tw-hidden!' id='martes' value='Martes' v-model="DiasSeleccionados" />
                                <label :class='{active:DiasSeleccionados.some(dia => dia === "Martes")}' for="martes" class='boton-dia tw-py-1! tw-px-2! tw-cursor-pointer tw-select-none'>Ma</label>
                            </div>
                            <div class='p-field-checkbox '>
                                <Checkbox class='tw-hidden!' id='miercoles' value='Miércoles' v-model="DiasSeleccionados" />
                                <label :class='{active:DiasSeleccionados.some(dia => dia === "Miércoles")}' for="miercoles" class='boton-dia tw-py-1! tw-px-2! tw-cursor-pointer tw-select-none '>Mi</label>
                            </div>
                            <div class='p-field-checkbox '>
                                <Checkbox class='tw-hidden!' id='jueves' value='Jueves' v-model="DiasSeleccionados" />
                                <label :class='{active:DiasSeleccionados.some(dia => dia === "Jueves")}' for="jueves" class='boton-dia tw-py-1! tw-px-2! tw-cursor-pointer tw-select-none'>Ju</label>
                            </div>
                            <div class='p-field-checkbox '>
                                <Checkbox class='tw-hidden!' id='viernes' value='Viernes' v-model="DiasSeleccionados" />
                                <label :class='{active:DiasSeleccionados.some(dia => dia === "Viernes")}' for="viernes" class='boton-dia tw-py-1! tw-px-2! tw-cursor-pointer tw-select-none'>Vi</label>
                            </div>
                            <div class='p-field-checkbox '>
                                <Checkbox class='tw-hidden!' id='sabado' value='Sábado' v-model="DiasSeleccionados" />
                                <label :class='{active:DiasSeleccionados.some(dia => dia === "Sábado")}' for="sabado" class='boton-dia tw-py-1! tw-px-2! tw-cursor-pointer tw-select-none'>Sa</label>
                            </div>
                            <div class='p-field-checkbox '>
                                <Checkbox class='tw-hidden!' id='domingo' value='Domingo' v-model="DiasSeleccionados" />
                                <label :class='{active:DiasSeleccionados.some(dia => dia === "Domingo")}' for="domingo" class='boton-dia tw-py-1! tw-px-2! tw-cursor-pointer tw-select-none'>Do</label>
                            </div>
                        </div>
                    </div>
                    
                    <Button :loading='isLoadSugerencias' :disabled='!especialidad || !medico || !coberturaValida' label='Consultar Citas Disponibles' class='tw-w-full' @click="handleConsultarTurnos"></Button>
                </div>
                
            </template>
        </Card>
        
        <Dialog appendTo="self" position="right" class='lg:tw-mr-20!' header="Citas Disponibles" :style="{width: '50vw',}" :breakpoints="{'960px': '75vw', '640px': '100vw', '425px' : '100vw'}" v-model:visible="consultarTurnosForm">
        
            
            <DataTable class='table-turnos-disponibles' :value="turnosDisponiblesList" selectionMode="single" v-model:selection="turnoSeleccionado" responsiveLayout="stack" scrollHeight="50vh">
                <template #empty>
                    No se encontraron datos.
                </template>
                <Column  field="fecha"  class='tw-w-32'  header="Fecha"></Column>
                <Column field="hora" class='tw-w-12' header="Hora"></Column>
                <Column field="profesional" header="Profesional"></Column>
                <Column field="especialidad"  header="Especialidad"></Column>
                <Column field="sucursal"  header="Sucursal"></Column>
                

            </DataTable>
        
            <template #footer> 
                <hr class='tw-mt-2'>
                <div class='tw-pt-2'>
                    <Button :disabled='!turnoSeleccionado' type="button" class='p-button-primary ' :class='turnoSeleccionado ? "" : "p-button-outlined"' :loading='isLoadTurnosCreate'  @click='handleConfirmarSeleccion'>Confirmar</Button>
                    <Button label='Salir' class='p-button-primary p-button-outlined tw-px-8' @click="handleCancelarSeleccion"></Button>
                </div>
            </template>
        </Dialog>
    </div>
    
</template>

<script lang='ts'>
import { defineComponent, ref, onBeforeMount, nextTick, computed } from "vue";
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";
import axios from '@/plugins/axios';
import { AxiosError } from "axios";

//Table
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';


import moment from 'moment';
import { TipoTurno } from '@/schemas/TipoTurno'

const encodeURIComponente = (objeto:any) => {
    return encodeURIComponent(JSON.stringify(objeto))
}


export default defineComponent({
    components:{
        DataTable,
        Column,
        
    },
    setup: () => {
        

        
        const router = useRouter();
        const route = useRoute();
        const store = useStore()
        const dialog = useConfirm();
        const coberturaValida = ref(false);

        const isLoadSugerencias = ref(false);
        const isLoadTurnosCreate = ref(false);

        const paginaActual = ref(0);
        const elemento = ref()

        const toast = useToast();

        const avanzadosToggle = ref(false);
        const fecha = ref([new Date(moment().add(1, 'days').toISOString(true)),new Date(moment().add(2, 'months').calendar())]);
        const horaDesde = ref(new Date(new Date().setHours(0,0,0,0)))
        const horaHasta = ref(new Date(new Date().setHours(23,59,0,0)))

        const turnosDisponiblesList = ref([])
        const turnoSeleccionado = ref();
        
        const especialidadesList = ref([{}])
        const sucursalesList = ref([{}])
        const medicosList = ref([{}])
        
        const especialidad = ref(null);
        const medico = ref();
        const sucursal = ref();
        
        const DiasSeleccionados = ref(["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"]);

        
        const consultarTurnosForm = ref(false);

        const filtrarVideoConsulta = ref(false)
        const tiposTurnosDisponiblesList = ref<TipoTurno[] | undefined>()

        const eventoDeScroll = (e:any) => {
            const { target:{scrollTop, offsetHeight, scrollHeight} } = e;
                    
            if(scrollTop + offsetHeight >= scrollHeight){
                obtenerTurnosDisponibles();
            }
        }


        const scrollBottom = () => {
            
            elemento.value?.removeEventListener('scroll',eventoDeScroll);

            elemento.value = document.querySelector('.p-dialog-content')
            
            elemento.value?.addEventListener('scroll',eventoDeScroll)

        }

        const handleConsultarTurnos = async () => {
            const paciente = store.state.pacienteLogueado
            
            if(!paciente.celular){
                dialog.require({
                    icon: 'pi pi-exclamation-triangle',
                    header:'Advertencia!',
                    message:'Para continuar debe ingresar un número telefonico de contacto.',
                    acceptLabel:'Continuar',
                    rejectLabel:'Cancelar',
                    accept:() => {
                        router.push({path:'mis-datos', query:{q:'telefono'}, params:route.params});
                    }
                })
                return;
            }

            if(!paciente.email){
                dialog.require({
                    icon: 'pi pi-exclamation-triangle',
                    header:'Advertencia!',
                    message:'Para continuar debe actualizar el email de contacto.',
                    acceptLabel:'Continuar',
                    rejectLabel:'Cancelar',
                    accept:() => {
                        router.push({path:'mis-datos',query:{q:'email'}, params:route.params});
                    },
                    reject:() => consultarTurnos()
                })
                return;
            }

            consultarTurnos();

            
        }

        const consultarTurnos = async () => {
            try {
                paginaActual.value = 0;
            
            
                await obtenerTurnosDisponibles()
                
                
                consultarTurnosForm.value = true
                
                elemento.value?.scroll({top:0});

                await nextTick();

                

                scrollBottom()
                
            } catch (error) {
                console.log(error);
            }
        }


        const handleConfirmar = async () =>{
            const fecha = turnoSeleccionado.value.fecha;
            const hora = turnoSeleccionado.value.hora;
            const profesional = turnoSeleccionado.value.profesional;
            
            try {
               
                
                dialog.require({
                    message:`se agendara un cita con fecha ${fecha} a la hora ${hora} con ${profesional}.`,
                    header:'Confirmar Cita',
                    icon: 'pi pi-exclamation-triangle',
                    accept: handleConfirmarSeleccion,
                })



                
            } catch (error) {
                console.log(error);
            }



            
        }

        const verificarLimite = async () =>{
            try {
                const limite = store.state.configuracionPortal.limiteTurnos;
                
                 const filters ={
                    idcliente:store.state.configuracionPortal.idcliente,
                    idpaciente:store.state.pacienteLogueado.id,
                    fecha:{ '>=':moment()},
                }

                const { data } = await axios.get(`turnos?filters=${encodeURIComponente(filters)}`)

                const filtrados = data.turnos.filter((turno:any )=> {
                    if(turno.usuario.nombre !== 'PORTAL')return false;
                    
                    if(turno.fecha === moment().format('YYYY-MM-DD')){
                        return turno.hora >= moment().format('HH:mm:ss');
                    }

                    return true;



                })

                console.log(filtrados);

                if(filtrados.length >= limite){
                    toast.add({
                        severity:'error',
                        summary:'Limite de cita',
                        detail:'Ya no puede agendar mas citas',
                        life:3000
                    })
                    return false;
                }

                return true;
                
            } catch (error) {

                console.log(error);

                return false;


                
            }
        }

        const verificarDisponibilidad = async () =>{
            try {
                const fecha = turnoSeleccionado.value.fecha;
                const especialidad = turnoSeleccionado.value.idmedicoespecialidad;
                const filters = {
                    fecha:moment(fecha, 'DD/MM/YYYY').format('YYYY-MM-DD'),
                    idmedicosespecialidad:especialidad,
                }


                const { data } = await axios.get(`turnos?filters=${encodeURIComponente(filters)}`)

                const turnos = data.turnos.filter((turno:any) => turno.paciente.id === store.state.pacienteLogueado.id)

                if(turnos.length > 0){
                    toast.add({
                        severity:'error',
                        summary:'Error',
                        detail:'Ya posee una cita en esa fecha para esa especialidad.',
                        life:3000
                    })
                    return false;
                }
               
                return true;
            } catch (error) {
                console.log(error)
                toast.add({
                    severity:'error',
                    summary:'Error',
                    detail:'Error al agregar la nueva cita.',
                    life:3000
                })
                return false;
            }
        }

        const handleConfirmarSeleccion = async () => {
            const data = turnoSeleccionado.value


            if(!(await verificarDisponibilidad()))return;



            isLoadTurnosCreate.value = true;

            try{
                const { carnet, id, v_o,idplancobertura,idcobertura} = store.state.pacienteLogueado;
                const {idcliente,cliente} = store.state.configuracionPortal
                


                if(!(await verificarLimite())) return;
                


                const result = await axios.post('turnos',{
                    sobreturno: false,
                    hora: moment(data?.hora,'HH:mm').format('HH:mm:ss'),
                    fecha: moment(data?.fecha,'DD/MM/YYYY'),
                    presente: false,
                    idmedicosespecialidad: data?.idmedicoespecialidad,
                    idusuario: cliente.usuarios[0].id,
                    idcliente,
                    idpaciente:id,
                    idcobertura,
                    idplancobertura,
                    carnet,
                    v_o,
                    fechaasignacion: moment(),
                    horaasignacion: moment().format('HH:mm:ss'),
                    idtipoturno: idTipoTurnoSeleccionado.value
                })


                toast.add({severity:'success',summary:'Exito',detail:'Cita agendada exitosamente.',life:1500})
                consultarTurnosForm.value = false;
                
                enviarMail(result.data);
                
                router.push({path:'mis-turnos', query:{q:result.data.id}, params:route.params});
                

                
                
            }catch(err){
                const { response } = err as AxiosError;
                const errMsg = response?.data?.message || 'Se produjo un error al crear la cita.'
                toast.add({severity:'error',summary:'Error',detail:errMsg,life:1500})
               
            }finally{
                isLoadTurnosCreate.value = false;
                turnoSeleccionado.value = undefined;
            }

            

        }

        const handleCancelarSeleccion = () => {
            turnoSeleccionado.value = null;
            consultarTurnosForm.value = false;
        }

        const handleChangeEspecialidad = (evt:{value:any , originalEvent:Event}) =>{
            obtenerMedicos(evt.value || undefined);
        }   


        const obtenerMedicos = async (Especialidad?:any) =>{
            try {
                const EspecialidadFiltrada = Especialidad.medicoEspecialidads; 


                console.log(EspecialidadFiltrada)
                
                const medicos = EspecialidadFiltrada
                .filter((medEsp:any)=> medEsp.medico.activoturnos === 'true')
                .map((medicoEsp:any)=>{
                    return {
                        ...medicoEsp.medico,
                        sucursal:{...medicoEsp.sucursal},
                    }
                })
                
               
                if(Especialidad){
                    sucursalesList.value = [{direccion:'Sucursal: Todas'}];
                    obtenerSucursales(medicos);
                }

                const medicosSinDuplicidad:any = [
                    ...medicos.reduce((acc:any,medico:any)=>{
                        if(!acc.some((med:any)=> med.id === medico.id)){
                            acc.push(medico);
                        }
                        return acc;
                    },[]).sort((medA : any, medB : any) => medA.nombre > medB.nombre ? 1 : -1)
                ];

                medicosList.value = [{nombre:'Profesional: Todos'},...medicosSinDuplicidad];
                
                medico.value = medicosList.value[0]
            } catch (error) {
                console.log(error);
            }
        }

        const obtenerEspecialidades = async () => {
            try {
                const especialidadesPermitidas = store.state.configuracionPortal.parametros.especialidades.top;
                const medicosPermitidos = store.state.configuracionPortal.parametros.especialidades.children;

                const filters = encodeURIComponent(JSON.stringify({
                    tipo:'E',
                    orarr:[
                        {id:{in:[...especialidadesPermitidas]}},
                        {'$medicoEspecialidads.id$':{in:[...medicosPermitidos]}}
                    ]
                }));
                const includes = encodeURIComponent(JSON.stringify([
                    {
                        required:true,
                        model:'medicoEspecialidad',
                        include:[
                            {model:'medico', required:true},
                            {model:'sucursal'}
                        ]
                    }
                ]));
                const result = await axios.get(`especialidades-servicios/get-all?filters=${filters}&includes=${includes}`);     
                 
                const esp = [...result.data.servEsp];
                
                
                esp.length > 0 
                    ? especialidadesList.value = [...esp].sort((espA, espB) => espA.descripcion > espB.descripcion ? 1 : -1) 
                    : especialidadesList.value = [];
                

                


                
            } catch (error) {
                console.log(error)
            }
        }

        const obtenerSucursales = (medicos:any) => {
            const sucursales = [...medicos.map((med:any)=> med.sucursal)];
            const hashSucursales:number[] = [];

            sucursales.forEach((suc:any) => {
                if(!hashSucursales.includes(suc.id)){
                    hashSucursales.push(suc.id);
                    sucursalesList.value.push({...suc, direccion:`${suc.sucursal || ''} - ${suc.direccion || ''}`});
                }
            });

            sucursal.value = sucursalesList.value[0];

            sucursalesList.value.sort((sucA : any, sucB : any) => sucA.direccion.includes('Todas') ? -1 : sucA.direccion > sucB.direccion ? -1 : 1 )
        }

        const obtenerTurnosDisponibles = async() =>{
            const diasDeLaSemana = ["Domingo","Lunes","Martes","Miércoles","Jueves","Viernes","Sábado"]
            
            isLoadSugerencias.value = true;
            
            const especialidadSelected = especialidad.value ? getObjectProps(especialidad.value, ['id']) : undefined


            const query = {
                origen: 'portal',
                especialidad:especialidadSelected,
                fechas:fecha.value,
                medico: medico.value?.id ? [medico.value.id] : [...medicosList.value.map((med:any)=>med.id)],
                dias:DiasSeleccionados.value,
                sucursal:sucursal.value?.id ? sucursal.value : null,
                pagina:paginaActual.value,
                ...(filtrarVideoConsulta.value ? {turnoVirtual: turnosVirtuales.value} 
                    : {turnoPresencial: [idTipoTurnoSeleccionado.value]}
                )
            } 

            




            
           try{
                
                const result = await axios.get(`turnos-sugerir?query=${encodeURIComponente(query)}`)
                
                
                if(paginaActual.value === 0){
                    turnosDisponiblesList.value = [];
                }
                
                
                paginaActual.value = result.data.paginaActual;
                
                const dataProcesada = result.data.TurnosDisponibles.map((registro:any) => {
                    return {
                        ...registro,
                        fecha:moment(registro.fecha).locale('es').format('ddd DD/MM/YYYY'),
                        dia:diasDeLaSemana[moment(registro.fecha).weekday()],
                    }
                })
                
                turnosDisponiblesList.value = turnosDisponiblesList.value.length === 0 ? 
                   ordenarPorFecha(dataProcesada)  : [...turnosDisponiblesList.value, ...ordenarPorFecha(dataProcesada)];

           }catch(err){
               console.log(err);

           }finally{
               isLoadSugerencias.value = false;
           }
        }

        const ordenarPorFecha = (turnos:any) =>{
            const turnosList = turnos.sort((a:any,b:any) => {
                if(moment(a.fecha + ' ' + a.hora, 'DD/MM/YYYY mm:ss').isAfter(moment(b.fecha + ' ' + b.hora,'DD/MM/YYYY mm:ss'))){
                    return 1
                }
                if(moment(a.fecha + ' ' + a.hora, 'DD/MM/YYYY mm:ss').isBefore(moment(b.fecha + ' ' + b.hora,'DD/MM/YYYY mm:ss'))){
                    return -1
                }else{
                    return 0
                }
            })
            return turnosList
        }

        const enviarMail = async (turno:any) => {
            try {
                const titulo = 'NUEVA CITA ASIGNADA';
                
                const { nombre ,email } = store.state.pacienteLogueado;
                const { cliente } = store.state.configuracionPortal
                
                const fecha = moment(`${turno.fecha} ${turno.hora}`,'YYYY-MM-DD HH:mm:ss');
                
                const medico = turno.medicoEspecialidad.medico.nombre;
                const especialidad = turno.medicoEspecialidad.servicioEspecialidad.descripcion;
                const sucursal = `${turno.medicoEspecialidad.sucursal.sucursal} - ${turno.medicoEspecialidad.sucursal.direccion}`

                let fechaIcs = fecha.toISOString();
                fechaIcs = fechaIcs.replaceAll('-','').replaceAll(':','').replaceAll('.','');


                await axios.post('/send-email/send', {
                    toEmails:[email],
                    template_id:'d-50d122b4f0124e428c4f1ffdde3f20c1',
                    dynamic_template_data:{
                        logo:cliente.descripcion,
                        titulo:titulo,
                        nombre:`${nombre}`,
                        medico:`${medico}`,
                        especialidad:`${especialidad}`,
                        fecha:`${fecha.format('DD/MM/YYYY')}`,
                        hora:`${moment(turno.hora,'HH:mm:ss').format('HH:mm')}`,
                        direccion:`${sucursal}`

                    },
                    atachment:{
                        type:'ics',
                        data:`BEGIN:VCALENDAR\nVERSION:2.0\nPRODID:-//hacksw/handcal//NONSGML v1.0//EN\nBEGIN:VEVENT\nSUMMARY:Cita con Profesional ${medico}\nLOCATION:${sucursal}\nUID:20210210203529.594${email}\nDESCRIPTION:\nDTSTART:${fechaIcs}\nDTEND:${fechaIcs}\nEND:VEVENT\nEND:VCALENDAR\n`
                    }
                });
            } catch (error) {
                console.log(error);
            }
        }
        
        const obtenerTiposTurnosDisponibles = async () => {
            try {
                const { data } = await axios.get('/tipo-turnos/get-all')
                tiposTurnosDisponiblesList.value = data
            } catch (error) {
                console.log(error)
            }
        }

        const getObjectProps = (obj:any, props:string[]) => {
            return props.reduce((acc:any, prop:string) => ({ ...acc, [prop]: obj[prop] }), {})
        }

        const idTipoTurnoSeleccionado = computed( () => {
            if(filtrarVideoConsulta.value) {
                return turnosVirtuales.value?.[0]
            } else {
                const sinTipo =  tiposTurnosDisponiblesList.value?.find( tipo => tipo.descripcion === 'SIN TIPO' && tipo.idcliente === null)
                if(sinTipo) {
                    return sinTipo.id
                } else {
                    return null
                }
            }
        })

        onBeforeMount(() => {
            
            obtenerEspecialidades()
            obtenerTiposTurnosDisponibles()

            sucursalesList.value = [{direccion:'Sucursal: Todas'}];
            sucursal.value = sucursalesList.value[0];
            medicosList.value = [{nombre:'Profesional: Todos'}];
            medico.value = medicosList.value[0];
            coberturaValida.value = store.getters.coberturaPermitida;
            
        })
        const isIOS = computed( () => {
            return [
                'iPad',
                'iPhone',
                'iPod'
            ].includes(navigator.platform)
            || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
        })

        const turnosVirtuales = computed( () => {
            return tiposTurnosDisponiblesList.value?.filter( tipo => tipo.virtual === filtrarVideoConsulta.value)
                ?.map(tipo => tipo.id) || []
        })

        return {
            filtrarVideoConsulta,
            tiposTurnosDisponiblesList,
            isIOS,
            isLoadTurnosCreate,
            isLoadSugerencias,
            avanzadosToggle,
            coberturaValida,
            fecha, 
            horaDesde, 
            horaHasta, 
            especialidadesList,
            especialidad,
            handleChangeEspecialidad, 
            medicosList, 
            medico, 
            sucursalesList, 
            sucursal, 
            DiasSeleccionados, 
            turnosDisponiblesList, 
            turnoSeleccionado, 
            consultarTurnosForm, 
            turnosVirtuales,
            handleConsultarTurnos,
            handleConfirmar, 
            idTipoTurnoSeleccionado,
            handleConfirmarSeleccion, 
            handleCancelarSeleccion,
            obtenerTurnosDisponibles,
            obtenerTiposTurnosDisponibles
        }
    }
})
</script>

<style lang='scss'>
@function blueIntense($cantidad){
    @return var(--blue-+$cantidad);
}


.table-turnos-disponibles .p-datatable-wrapper .p-datatable-table .p-datatable-tbody {
    & > tr:nth-child(even){
        background:blueIntense(50);
        border-bottom:1px solid blueIntense(100);
        border-top:1px solid blueIntense(100);
        
    }
    & > tr.p-highlight{
        background:blueIntense(200);
    }
    @media screen and (max-width: 960px){
        & > tr > td{
            padding:0;
        }
    }
    
}

.nuevo-turno {
    .animate{
        animation: entrada-nuevo-turno .2s ease forwards;
    }
    .boton-dia{
        border: 1px solid #414141;
        /* width:90%;
         */
        /* border-radius:.2em; */
        display:inline-block;
        transition: background-color .2s ease;
        &.active{
            background-color:blueIntense(200);
        }
    }
}


@keyframes entrada-nuevo-turno {
    0%{
        opacity: 0;
        transform: translateX(-200%);
    }100%{
        opacity: 1;
        transform: translateX(0%);
    }
}
</style>