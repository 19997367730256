<template>
    <div class='tw-p-2 menuMobile tw-bg-gray-50' :class='{"lg:tw-hidden tw-w-full":!isMenuBar,"tw-hidden! lg:tw-block! tw-w-96" : isMenuBar && !isSlide }'>
        <Card class='tw-w-full! tw-h-full!  tw-shadow-none' :class='{"tw-absolute! tw-top-0! tw-left-0!":!isMenuBar}'>

        <template #content>
            <div style='height:calc(100% - 2.5em);' class='tw-w-full! tw-flex! tw-flex-col tw-items-center' :class='{"tw-justify-center":isMenuBar}'>
                <div class='tw-w-full tw-text-center tw-flex tw-flex-col tw-justify-center tw-items-center tw-mb-auto'>
                    <img :src="logo" alt="logo de la clinica" class='tw-w-full tw-rounded-full tw-shadow-lg tw-border tw-border-4 tw-border-gray-200 imagen'>
                    <span class='tw-text-lg tw-mt-2'><strong>{{$store.state.configuracionPortal.cliente.descripcion}}</strong></span>
                </div>
        
                <ul class='tw-space-y-2 tw-w-full tw-mb-auto'>
                    <li v-for='item in items' :key='item' :class='{"item-active":$route.name === item.name}'  class='tw-w-full  tw-text-left tw-text-lg item tw-border-t tw-border-b'>
                        <router-link  v-if='item.label !== "Cerrar sesion"' :to='item.to || ""' v-slot='{navigate}'>
                            
                            <span @click='navigate(); $emit("clicked")' class='tw-block tw-w-full tw-flex tw-items-center tw-space-x-2 ' >
                                <i class='tw-mr-2 tw-text-center tw-p-2 tw-h-full tw-border tw-rounded tw-bg-blue-500 tw-text-gray-200 tw-shadow' :class='item.icon'></i>
                                <span class=' tw-text-center '>{{item.name}}</span>
                            </span>
                        </router-link>
                        

                        <div class='tw-cursor-pointer tw-w-full tw-flex tw-items-center tw-space-x-2' v-else @click.prevent="item.command();  $emit('clicked')">
                            <i :class="item.icon || 'pi pi-sign-out'" class='tw-mr-2 tw-text-center tw-p-2 tw-h-full tw-border tw-rounded tw-bg-blue-500 tw-text-gray-200 tw-shadow'></i>
                            <span class=' tw-text-center '>{{item.label}}</span>
                        </div>
                        
                    </li>
                
                
                </ul>
            </div>

            <div  v-if='isMenuBar && !isSlide' class='tw-w-full tw-mt-auto tw-py-2 tw-text-center'>
              <a class='tw-w-full tw-text-center' href="https://globalapp.ar" target='_blank'><img class='mx-auto' src="../assets/LogoWeb.png" alt="logo" style='height:3em;'></a>
            </div>
            <div  v-else class='tw-w-full tw-text-center'>
              <a class='tw-w-full tw-text-center' href="https://globalapp.ar" target='_blank'><img class='mx-auto' src="../assets/LogoWeb.png" alt="logo" style='height:3em;'></a>
            </div>
        
        </template>
        
        </Card>
    </div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue';
import { useConfirm } from "primevue/useconfirm";
import {  useRouter } from 'vue-router';
import { useStore } from 'vuex';
import useLogo from '@/hooks/useLogo';

export default defineComponent({
    props:['isMenuBar', 'isSlide'],
    setup() {
        const store = useStore()
        const router = useRouter();
        const dialog = useConfirm();
        const logo = useLogo();

         const items = ref([
        {
          id:1,
          icon:'pi pi-plus',
          label:'Nuevo Turno',
          to:'nuevo-turno',
          name:'Nueva Cita'
        },
        {
          id:2,
          icon:'pi pi-list',
          label:'Mis turnos',
          to:'mis-turnos',
          name:'Mis Citas'
        },
        {
          id:3,
          icon:'pi pi-folder',
          label:'Resultados de estudios',
          to:'resultados',
          name:'Resultados'
        },
        {
          id:4,
          icon:'pi pi-file-o',
          label:'Recetas',
          to:'recetas',
          name:'Recetas'
        },
        {
          id:5,
          icon:'pi pi-user-edit',
          label:'Mis datos',
          to:'mis-datos',
          name:'Mis Datos'
        },
        {
          label:'Cerrar sesion',
          command: ()=>{
            dialog.require({
              message:'Seguro que desea cerrar sesion?',
              header:'Cerrar Sesion',
              icon: 'pi pi-exclamation-triangle',
              rejectLabel:'Si',
              acceptLabel:'No',
              acceptClass:'p-button-link',
              rejectClass:'p-button-primary',
              reject: () => {
                store.dispatch('cerrarSesion')
                router.push('login');
              },
            })
          }
        }
      ])

      onMounted(()=>{
        window.scrollTo( 0, 0 );
      })



        return {
            items,
            logo,
        }
    }
})
</script>


<style lang="css">


.menuMobile .imagen{
    width: clamp(100px, 2.5vw, 150px);
    height: clamp(100px, 2.5vw, 150px);
    object-fit: contain;
}
.menuMobile .item{
    font-size: clamp(.9rem, 2.5vw,1.2rem ) !important;
}
.menuMobile .item .pi{
    font-size: 1.4rem !important;
}

.menuMobile .item{
    padding:.5em;
}


.menuMobile .p-card .p-card-body{
    width: 100% !important;
}
.menuMobile .p-card .p-card-body,.menuMobile .p-card .p-card-body .p-card-content{
    height: 100% !important;
}
.menuMobile .item:hover{
    background: var(--blue-300) ;
    color: var(--gray-100);
    box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.4), 0px 0px 5px var(--blue-400);
}

.menuMobile .item.item-active{
    background: var(--blue-400) ;
    color: var(--gray-100);
    box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.4), 0px 0px 5px var(--blue-400);
}

    
</style>