import { BASE_URL } from '@/plugins/axios';
import { useStore } from 'vuex';



const useBackground = () => {
    const store = useStore();

    let url;


    url = store.state.configuracionPortal.imagenFondo;
    
    
    return url;

    
}

export default useBackground;