<template>
    <Toast position="bottom-right"/> 
    <div class='resultados tw-w-full tw-flex tw-justify-center tw-h-full  tw-items-center tw-p-2'>
        <Card style='background:none;box-shadow:none;' class='animate tw-w-full tw-h-full! p-shadow-5'>
            <template #title>
                <div class='tw-bg-gray-50 tw-rounded tw-py-4 tw-shadow-lg'>
                    <h3>Resultados</h3>
                    <span class='tw-text-sm'>Es IMPORTANTE que la interpretación de estos Resultados la realices junto a tu médico.</span>
                </div>
            </template>
            <template #content>
                <div class="tw-w-full tw-flex tw-items-center tw-justify-center">
                    <SelectButton @change="handleTipoChange" size="small" :options="tipos" v-model="tipoSeleccionado" aria-labelledby="basic" />
                </div>
                <div ref='contenedorResultados' v-if='evolucionesList?.length > 0' style='max-height:calc(100vh - 34vh);' class='tw-w-full tw-grid xl:tw-grid-cols-2 tw-gap-2 tw-mx-auto tw-mt-2 tw-bg-gray-100 tw-overflow-auto tw-p-2 tw-rounded'>
                    <resultados-card  v-for="item in evolucionesList" :key="item" @ver='handleVerClick(item)' @visualizar="handleVisualizar(item)" :datos='item'></resultados-card>  
                </div>
                <div v-else style='max-height:calc(100vh - 35vh)' class='tw-w-full tw-mt-2 tw-bg-gray-50 tw-overflow-auto tw-p-2 tw-space-y-2 tw-rounded'>
                    <span>No se encontraron resultados.</span>
                </div>
            </template>
        </Card>

    </div>
    <!--  -->
    <Dialog v-model:visible="resultadoFormActive" class="popup-visorEstudios" :style="{'width':'100%','height':'100%'}">
        <template #header> <div class='tw-w-full'></div> </template>
        <div v-if='resultadoFormActive' class='tw-w-full tw-h-full'>
            <iframe :src="evolucionSeleccionada?.pac?.estudio?.visor" frameborder="0" width="100%" height="100%"></iframe>
        </div>
    </Dialog>

</template>

<script lang='ts'>
import { defineComponent, ref, onBeforeMount, onMounted, computed ,watchEffect} from 'vue'
import { useToast } from "primevue/usetoast";

import ResultadosCard from '@/components/ResultadosCard.vue'
import axios from '@/plugins/axios';
import moment from 'moment';

import { useStore } from 'vuex';
import { jsPDF } from 'jspdf';

import Pac from '@/modules/Pac'


import { imprimirHC } from '@/hooks/useImpresion';


export default defineComponent({
    components:{
        ResultadosCard
    },
    setup: () => {
        const toast = useToast();
        const store = useStore();
        const contenedorResultados = ref<HTMLDivElement>();
        const resultadoFormActive = ref(false);
        
        const medicos = ref([{nombre:'Patricio Esponja'}]);
        const medicoSeleccionado = ref(null);
        const especialidades = ref([{nombre:'Pediatria'}]);
        const especialidadSeleccionada = ref(null);
        const fecha = ref([new Date()]);

        const tipos = ref(['Todo', 'Estudios', 'Imagenes']);
        const tipoSeleccionado = ref('Todo');

        
        const evolucionesList = ref();
        const evolucionSeleccionada = ref();
        const pagina = ref(1);
        const itemsPorPagina = ref(10);
        const cantidadResultados = ref();
        
        
        const handleTipoChange = () => {
            obtenerRegistros(true)
        }


        const handleVerClick = async (item:any) =>{
            try {

                const pdfjs =  new jsPDF({
                    format: 'a4',
                    unit:'pt',
                    hotfixes:["px_scaling"]
                });
                
                const result = await axios.get(`/historia-clinica-get-registros/${item._id}`)
                evolucionSeleccionada.value = result.data;

                

                if(evolucionSeleccionada.value.tipo === 'PAC'){
                    const pac = await Pac.getInformeHC(evolucionSeleccionada.value);
                    await downloadPacArchive(pac);
                    return;
                }

                if(!evolucionSeleccionada.value.medico?.logofirma){
                    toast.add({severity:'warn',summary:'Atencion',detail:'Resultado sin firma.',life:1500})
                    return;
                }
                
                await imprimirHC(evolucionSeleccionada.value,pdfjs, store.state.configuracionPortal.cliente.logo);
                pdfjs.save(`${evolucionSeleccionada.value.datos.nombre} - ${moment(evolucionSeleccionada.value.datos.fecha).format('DD_MM_YYYY')}.pdf`);
            } catch (error) {
                console.log(error);
            }
        }

        const handleVisualizar = async (item:any) => {
            const result = await axios.get(`/historia-clinica-get-registros/${item._id}`)
            evolucionSeleccionada.value = result.data;
            const pac = await Pac.getInformeHC(evolucionSeleccionada.value);
            evolucionSeleccionada.value.pac = pac;
            resultadoFormActive.value = true;
        }


        const downloadPacArchive = async (estudio:any) => {
            const { data } = await axios.get('get-url?url=' + estudio.estudio.informeurl, { responseType: 'blob' })
            if(data.size < 1000) {
                toast.add({severity:'warn',summary:'Atencion',detail:'Estudio no disponible.',life:1500})
            } else {
                let reader = new FileReader();
                reader.readAsDataURL(data);
                reader.onloadend = () => {
                    let base64data = reader.result;
                    let link = document.createElement('a');
                    link.href = base64data as string;
                    link.download = `Diagnostico Por Imagen` + '.pdf';
                    link.click();
                }
            }
        }

        const obtenerRegistros = async (isNew = false) => {
            try {
                const fechas = formatearFechas();

                const filtrosTipo : Record<string,any> = {
                    Todo: {$or:[{tipo:'HC'},{tipo:'PAC'}]},
                    Estudios: {tipo:'HC'},
                    Imagenes: {tipo:'PAC'}
                }

                const query = encodeURIComponent(JSON.stringify({...(filtrosTipo[tipoSeleccionado.value] || {}),'datos.isPublicable':true} || {}));
                
                const {data} = await axios.get(`/historia-clinica-get-registros/${store.state.pacienteLogueado.id}/${pagina.value}/${itemsPorPagina.value}?query=${query}`);


                for(let dato of data.registros){
                    if(dato.tipo === 'PAC'){
                        dato.datos.nombre = 'Diagnostico Por Imagen';
                    }
                }
                
                
                evolucionesList.value = evolucionesList.value && !isNew ? [...evolucionesList.value, ...data.registros] : data.registros;
                cantidadResultados.value = data.count;
                especialidades.value = data.especialidad;
                medicos.value = data.medicos;
                
            } catch (error) {
                console.log(error);
            }
        }

        const cantidadPaginas = computed(() => {
            return Math.ceil(cantidadResultados.value / itemsPorPagina.value);
        });

        watchEffect(() => {
            
            if(contenedorResultados.value){
                contenedorResultados.value.removeEventListener('scroll', eventoDeScroll);
                contenedorResultados.value.addEventListener('scroll', eventoDeScroll);
            }
        })
        
        onBeforeMount(() => {
            obtenerRegistros();
        })

        const formatearFechas = () =>   fecha.value.length === 1 
                                        ? [...fecha.value,fecha.value[0]].map((fech:any) => moment(fech).format('YYYY-MM-DD'))
                                        : fecha.value.map((fech:any) => moment(fech).format('YYYY-MM-DD'));

        


        const eventoDeScroll = (e:any) => {
            const { target:{scrollTop, offsetHeight, scrollHeight} } = e;
            
            if(scrollTop + offsetHeight >= scrollHeight){
               
                pagina.value += 1;
                if(cantidadPaginas.value >= pagina.value){
                    
                    obtenerRegistros();
                }
                
                
            }
        }

        
        
        
        
        return {
            contenedorResultados,
            resultadoFormActive,
            medicos,
            medicoSeleccionado,
            especialidades,
            especialidadSeleccionada,
            fecha,
            evolucionesList,
            evolucionSeleccionada,
            tipos,
            tipoSeleccionado,
            handleVerClick,
            handleTipoChange,
            handleVisualizar,
        }
    }

})
</script>

<style>
.popup-visorEstudios.p-dialog .p-dialog-content {
    height: 100%;
}
.resultados .animate {
    animation: entrada-resultados .2s ease forwards;
}
@keyframes entrada-resultados {
    0%{
        opacity: 0;
        
    }100%{
        opacity: 1;
        
    }
}
</style>