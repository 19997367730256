<template>
    <Toast position="bottom-right" />
    <div class='mis-datos tw-w-full tw-h-full tw-flex tw-justify-center tw-items-center tw-px-4 md:tw-px-0'>
        <Card class='animate tw-w-full md:tw-w-10/12  xl:tw-w-6/12  '>
                
                <template #title> 
                    
                        <h3 class=''>MIS DATOS</h3>
        
                    <hr>
                </template>
                
                <template #content>
                    <div class='tw-w-full tw-space-y-4'>

                        
                        
                        <!-- Mail -->
                        <div class='tw-flex tw-justify-center tw-items-center tw-h-11'>
                            
                            <div class='tw-w-3/4 md:tw-w-8/12  tw-flex tw-justify-center tw-items-center'>
                                <InputText id='inputEmail' v-model="email" :disabled="!estaEditando" placeholder='ejemplo@correo.com' style='top:0; left:0;' class='tw-text-center tw-w-full tw-transition-all tw-duration-150' />
                            </div>
                        </div>

                        <!-- Telefono -->
                        <div class='tw-flex tw-justify-center tw-items-center tw-h-11'>
                            <div class='tw-w-3/4 md:tw-w-8/12 tw-relative tw-flex tw-justify-center tw-items-center'>
                                <InputNumber placeholder='+54' :disabled="!estaEditando" v-model="codpais" :useGrouping="false" :min="0" :max="999999" mode="decimal" class='numero-telefonico tw-justify-center tw-w-3/12 tw-transition-all tw-duration-150' />
                                <InputNumber id='inputTelefono' v-model="celular" :disabled="!estaEditando" placeholder='1123456789' :useGrouping="false" :min="0" :max="9999999999" class='numero-telefonico tw-w-9/12 tw-transition-all tw-duration-150' />

                                
                            </div>
                        </div>
                        
                        <!-- Direccion -->
                        <div class='tw-flex tw-justify-center tw-items-center tw-h-24'>
                            <div class='tw-w-3/4 md:tw-w-8/12 tw-h-full tw-relative tw-grid tw-grid-cols-1 tw-justify-center tw-items-center'>
                                <div class="tw-flex tw-flex-row tw-justify-center">
                                    <InputText v-model="direccion" disabled placeholder='Direccion' class='tw-text-center tw-w-full  tw-transition-all tw-duration-150' />
                                </div>
                                <div v-if="estaEditando || numero || piso || depto" class="tw-flex tw-flex-row tw-justify-center tw-space-x-2">
                                    <InputText v-model="numero" disabled placeholder='Nro' class='tw-text-center tw-w-full  tw-transition-all tw-duration-150' />
                                    <InputText v-model="piso" disabled placeholder='Piso' class='tw-text-center tw-w-full  tw-transition-all tw-duration-150' />
                                    <InputText v-model="depto" disabled placeholder='Dpt' class='tw-text-center tw-w-full  tw-transition-all tw-duration-150' />
                                </div>
                            </div>
                        </div>

                        <!-- Pais y Ciudad -->
                        <div class='tw-flex tw-justify-center tw-items-center tw-h-36'>
                            <div class='tw-w-3/4 md:tw-w-8/12 tw-relative tw-flex-col tw-space-y-2 tw-justify-center tw-items-center'>
                                <div class="tw-flex tw-flex-col tw-w-full tw-space-y-2">
                                    <InputText disabled placeholder='Localidad' v-model="localidad" class='tw-text-center tw-w-full tw-transition-all tw-duration-150' />
                                    <InputText disabled placeholder='Provincia' v-model="provincia" class='tw-text-center tw-w-full tw-transition-all tw-duration-150' />
                                    <InputText disabled placeholder='País' v-model="pais" class='tw-text-center tw-w-full tw-transition-all tw-duration-150' />
                                </div>
                            </div>
                        </div>

                        <div class="tw-flex tw-flex-row tw-justify-end">
                            <div class="tw-pt-2 tw-w-full! tw-flex tw-flex-row tw-space-x-4 tw-justify-end"> 
                                <Button @click="handleSaveButton()" class="" :label="estaEditando ? 'Guardar' : 'Editar'"></Button>
                                <Button @click="handleCancelButton()" :label="estaEditando ? 'Cancelar' : ' Salir   '" class='p-button-link'></Button>
                            </div>
                        </div>
                    </div>
                </template>
        </Card>
    </div>
    
    <Dialog class='tw-w-96' v-model:visible="editarPass" :modal="true">
        <template #header> <div class='tw-w-full'></div> </template>
        <div class='tw-w-full tw-flex tw-flex-col'>
            <div class="tw-w-full tw-pt-1 tw-flex tw-justify-center tw-items-center" >
                <div class='tw-w-full tw-pt-4 tw-pb-2'>
                    <span class="p-float-label tw-w-full">
                        <Password id='passAnt' inputClass='tw-w-full' class='tw-w-full' :feedback='false' toggleMask ></Password>
                        <label for="passAnt">Contraseña anterior</label>
                    </span>
                </div>
            </div>
            <div class="tw-w-full tw-pt-1 tw-flex tw-justify-center tw-items-center" >
                <div class='tw-w-full tw-pt-4 tw-pb-2'>
                    <span class="p-float-label tw-w-full">
                        <Password id='passNuev' inputClass='tw-w-full' class='tw-w-full' toggleMask ></Password>
                        <label for="passNuev">Nueva contraseña</label>
                    </span>
                </div>
            </div>
            <div class="tw-w-full  tw-pt-1 tw-flex tw-justify-center tw-items-center" >
                <div class='tw-w-full tw-pt-4 tw-pb-2'>
                    <span class="p-float-label tw-w-full">
                        <Password id='repPassNuev' inputClass='tw-w-full' class='tw-w-full' toggleMask ></Password>
                        <label for="repPassNuev">Repita su nueva contraseña</label>
                    </span>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label='Guardar'></Button>
            <Button label='Cancelar' class='p-button-link' @click="handleEditarPassClick(false)"></Button>
        </template>
    </Dialog>
</template>

<script lang='ts'>
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import { defineComponent, ref, reactive, onMounted, nextTick } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router'


import { useToast } from 'primevue/usetoast';
import { useConfirm } from "primevue/useconfirm";
import axios from '@/plugins/axios';


export default defineComponent({
    components:{
        InputNumber
    },
    setup: () => {
        const store = useStore()
        const route = useRoute();
        const router = useRouter();
        const paciente = store.state.pacienteLogueado
        const logo = 'https://previews.123rf.com/images/karom/karom1610/karom161000146/67581679-logotipo-de-m%C3%A9dicos-logotipo-centro-m%C3%A9dico-logotipo-de-la-salud-logotipo-m%C3%A9dico-logotipo-de-medicina.jpg'
        const toast = useToast();
        const dialog = useConfirm();
        const estaEditando = ref(false)
        const editarPass = ref(false);
    
        
        const nombre = ref(paciente.nombre)
        const email = ref(paciente.email)
        const celular = ref(paciente.celular || null)
        const direccion = ref(paciente.calle)
        const numero = ref(paciente.numero)
        const codpais = ref(paciente?.codpais?.toString())
        const codarea = ref(paciente?.codarea?.toString())
        const piso = ref(paciente.piso)
        const depto = ref(paciente.depto)
        const localidad = ref(paciente.localidad)
        const provincia = ref(paciente.provincia)
        const pais = ref(paciente.pais)

        const edit = ref({email:email,celular:celular,direccion:direccion, numero:numero,codpais:codpais, codarea:codarea , piso:piso, depto:depto, localidad:localidad, provincia:provincia, pais:pais});
        

        const validateForm = () => {
            let error = false
            if(!celular.value){
                error = true
                dialog.require({
                    header:'Atención',
                    message:'Complete por favor su número de contacto.',
                    acceptLabel:'Aceptar',
                    rejectLabel:'',
                    rejectClass:'tw-hidden'
                })
            }
            return error
        }

        const handleSaveButton = () => {
            estaEditando.value ? savePaciente() : estaEditando.value = true
        }

        const handleCancelButton = () => {
            if(estaEditando.value){
                nombre.value = paciente.nombre
                email.value = paciente.email
                celular.value = paciente.celular || null
                direccion.value = paciente.calle
                numero.value = paciente.numero
                codpais.value = paciente?.codpais?.toString()
                codarea.value = paciente?.codarea?.toString()
                piso.value = paciente.piso
                depto.value = paciente.depto
                localidad.value = paciente.localidad
                provincia.value = paciente.provincia
                pais.value = paciente.pais
                estaEditando.value = false
            }else{
                router.push(`/${route.params.cliente}/`)
            }
        }

        const savePaciente = () =>{
            if(validateForm()){
                return
            }
            if(!email.value){
                dialog.require({
                    header:'Atención',
                    message:`Complete por favor su email de contacto. \nEs necesario para poder recuperar su contraseña de ingreso a futuro`.trim(),
                    acceptLabel:'Aceptar',
                    rejectLabel:'Cancelar',
                    rejectClass:'tw-hidden',
                    accept:() => confirmSavePaciente()
                })
                return;
            }
            confirmSavePaciente()
        }

        const confirmSavePaciente = async () => {
              try {
                const result = await axios.put(`pacientes/update/${paciente.id}` , {
                    email: email.value,
                    celular: celular.value == '-' ? '' : celular.value,
                    codarea: codarea.value,
                })
                store.state.pacienteLogueado = { ...store.state.pacienteLogueado, ...result.data.paciente}
                toast.add({severity:'success',summary:'Exito',detail:'Se actualizaron los datos correctamente',life:1500})
                estaEditando.value = false;
            } catch (error) {
                toast.add({severity:'error',summary:'Error',detail:'Error al actualizar los datos',life:1500});
                console.log(error);
            }
            
        }

        const formatoTelefono = (tel:string) =>{
            const ultimosCuatro = tel.slice(-4)
            const primerosCuatro = tel.slice(-8, -4)
            const codigoArea = tel.slice(0, -8)
            return `+54 (${codigoArea}) ${primerosCuatro}-${ultimosCuatro}` 
        }

        const handleEditarPassClick = (bool:boolean) =>{
            editarPass.value = bool;
        }


        onMounted(() => {
                
                const { q } = route.query;

                if(!q) return;

                estaEditando.value = true;


                nextTick(() => {
                    document.getElementById(q === 'email' ? 'inputEmail' : 'inputTelefono')?.focus()
                })
        })


        return {
            logo,
            edit,
            editarPass,
            nombre,
            email,
            celular,
            direccion,
            numero,
            codpais,
            codarea,
            piso,
            depto,
            localidad,
            provincia,
            pais,
            handleEditarPassClick,
            handleSaveButton,
            estaEditando,
            handleCancelButton,
        }
    }
})
</script>

<style>
.mis-datos .animate{
    animation: entrada-mis-datos ease .2s forwards;
}
.p-inputnumber-input{
    width: 100%;
}
.p-inputtext:disabled {
    background-color: #f3f2f1;
    border-color: #f3f2f1;
    color: #000000 !important;
    opacity: 1;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.numero-telefonico .p-inputtext{
    text-align: center
}
.p-confirm-dialog-message{
    font-size: 1.1em;
    white-space: pre;
}
@keyframes entrada-mis-datos {
    0%{
        opacity:0;
        transform: scale(0);
    }100%{
        opacity: 1;
        transform: scale(1);
    }
}
</style>