<template>
    <div class='tw-w-full p-shadow-3 tw-rounded tw-my-4 tw-p-4 tw-text-left tw-relative'>
        <div class='tw-absolute tw-top-0 tw-right-0'>
            <Button @click='handleCancelarSolicitud' class='p-button-rounded p-button-text p-button-danger' icon="pi pi-trash"></Button>
        </div>
        <div class='tw-w-full tw-pt-4'>
            <span style='color:var(--primary-color)' class='tw-font-bold tw-select-none'>{{fecha}}</span><br>
            <span class='tw-font-bold'>Pedido:</span>
            <pre class='tw-text-center'>{{solicitudPendiente.datos.pedido}}</pre>
        </div>
        
    </div>
</template>



<script>
import { defineComponent, ref, toRefs } from "vue";
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm"
import axios from '@/plugins/axios'
import moment from 'moment'
export default defineComponent({
    props:{solicitudPendiente:{
        type:Object,
        required:true
    }},
    setup(props, { emit }) {
        const { solicitudPendiente } = toRefs(props);
        const toast = useToast();
        const dialog = useConfirm();
        
        const es = moment(solicitudPendiente.value.fecha).locale('es')
        const fecha = es.format('dddd DD \\d\\e MMM \\d\\e\\l YYYY - HH:mm')
        
        
        const handleCancelarSolicitud = () =>{

            dialog.require({
                    message:`¿Seguro que desea cancelar esta solicitud?`,
                    header:'Cancelar Solicitud',
                    icon: 'pi pi-exclamation-triangle',
                    rejectLabel:'Si',
                    acceptLabel:'No',
                    acceptClass:'p-button-link',
                    rejectClass:'p-button-primary',
                    reject: cancelarSolicitud,
                })


        }

        const cancelarSolicitud = async () => {
            try {

                await axios.get(`/tareas-pendientes/${solicitudPendiente.value.id}`);


                toast.add({
                    severity:'success',
                    summary:'Solicitud cancelada',
                    detail:'La solicitud ha sido cancelada',
                    });
                
                emit('cancelarSolicitud');


            } catch (error) {
                toast.add({
                    type: "error",
                    icon: "pi pi-times",
                    message: error.message,
                    detail: error.response.data.detail,
                    life: 10000
                });   
            }
        }



        return {
            handleCancelarSolicitud,
            fecha
        }
    }
})
</script>